import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { Link } from "gatsby"
import styled from "styled-components";
import Bottom1 from "../assets/home-image-7_d.jpg";
import Bottom2 from "../assets/home-image-7_m.jpg";
import Client1 from "../assets/Affiliate Logos/1210038055855067_jacob-bros-construction_third_party_image 1.png"
import Client2 from "../assets/Affiliate Logos/Houle_Electric 1.png"
import Client3 from "../assets/Affiliate Logos/ledcor-logo 1.png"
import Client4 from "../assets/Affiliate Logos/mott-electrical 1.png"
import Client5 from "../assets/Affiliate Logos/Turner_Construction_logo 1.png"




const Clients = ({fluid}) => {

  const data = useStaticQuery(graphql`
  query {
  imageSharp(fluid: {originalName: {eq: "home-image-7_d.jpg"}}) {
    fluid {
      ...GatsbyImageSharpFluid
    }
  }
}
  `)

  return (
    <>
    <Title>Our Clients</Title>
    <Div>
          <Img1 src={Client1} alt=""/>
          <Img5 src={Client5} alt=""/>
          <Img3 src={Client3} alt=""/>
          <Img2 src={Client2} alt=""/>
          <Img4 src={Client4} alt=""/>
    </Div>

     <Div1>
        <Div2>
          <Img1 src={Client1} alt=""/>
          <Img5 src={Client5} alt=""/>
          <Img3 src={Client3} alt=""/>
        </Div2>

       <Div3>
          <Img2 src={Client2} alt=""/>
          <Img4 src={Client4} alt=""/>
       </Div3>
    </Div1>
    
     <ContImg>
       <Image fluid={fluid ? fluid : data.imageSharp.fluid} style={{
             width: "100%",
             height: "100%"
           }}/>
     </ContImg>
    </>
  
  )
}
const Div3 = styled.div`
display: flex;
justify-content: space-around;
margin-bottom: 8px;
`
const Div2 = styled.div`
display: flex;
justify-content: space-around;
padding-bottom: 12px;
`
const Div1 = styled.div`
display: none;

@media screen and (max-width: 700px) {
display: block;
margin-left: auto;
margin-right: auto;
}
`

const Img1 = styled.img`
width: 95px;
height: 95px;

@media screen and (max-width: 1110px) {
width: 70px;
height: 70px;
}
`
const Img2 = styled.img`
width: 163px;
height: 90px;

@media screen and (max-width: 1110px) {
width: 135px;
height: 60px;
}
`
const Img3 = styled.img`
width: 110px;
height: 78px;

@media screen and (max-width: 1110px) {
width: 83px;
height: 60px;
}
`
const Img4 = styled.img`
width: 64px;
height: 75px;

@media screen and (max-width: 1110px) {
width: 57px;
height: 67px;
}
`
const Img5 = styled.img`
width: 140px;
height: 41px;

@media screen and (max-width: 1110px) {
width: 90px;
height: 30px;
}
`
const Div = styled.div`
display: flex;
justify-content: space-around;
margin-bottom: 70px;

@media screen and (max-width: 1110px) {
margin-bottom: 36px;
}

@media screen and (max-width: 700px) {
display: none;
}
`

const Title = styled.h1`
font-weight: 700;
line-height: 48px;
font-size: 40px;
font-family: "GT America Bold";
padding-top: 35px;
padding-bottom: 43px;
color: #000000;
text-align: center;

@media screen and (max-width: 1110px) {
font-weight: 700;
line-height: 32px;
font-size: 24px;
padding-top: 33px;
margin-right: 24px;
padding-bottom: 20px;

}

@media screen and (max-width: 700px) {
font-weight: 700;
line-height: 32px;
font-size: 24px;
padding-top: 16px;
margin-left: 16px;
margin-right: 16px;
padding-bottom: 20px;
}
`

const ContImg = styled.div`
width: 100%;
height: 600px;

//this
// background-image: url(${Bottom1});
// background-size: 100% 100%;

@media screen and (max-width: 1110px) {
height: 500px;
}

@media screen and (max-width: 700px) {

  //this
// height: 350px;
height: 275px;
// background-image: url(${Bottom2});
margin-top: 64px;
}
`

export default Clients

import React, {useState} from "react"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/Layout"
import Sidebar from "../components/Sidebar"
import Footer from "../components/Footer"
import Carousal from "../components/Carousal"
import Top1 from "../assets/home-image-1m.jpg";
import Top2 from "../assets/home-image-1d.jpg";
import Clients from "../components/Clients";
import SEO from "../components/SEO"
import styled from "styled-components";




const  Index = ({fluid}) =>{

  const data = useStaticQuery(graphql`
  query {
  imageSharp(fluid: {originalName: {eq: "home-image-1d.jpg"}}) {
    fluid {
      ...GatsbyImageSharpFluid
    }
  }
}
  `)

  
  return (
   
      <Layout>
         <SEO title="Welcome to Scancore | Concrete Scanning-Coring-Cutting" description="this is the home page"/>
         <ContImg >
           <Image fluid={fluid ? fluid : data.imageSharp.fluid} style={{
             width: "100%",
             height: "100%"
           }}/>
         </ContImg>
         <Carousal/>
         <Clients/>
       </Layout>
    
  )
}


const ContImg = styled.div`
width: 100%;
height: 600px;

  @media screen and (max-width: 1110px) {
  height: 400px;

}

  @media screen and (max-width: 700px) {
  height: 350px;
  margin-top: 64px;
}
`

export default Index




